import logo1 from "assets/images/partner/1.png";
import logo2 from "assets/images/partner/2.png";
import logo3 from "assets/images/partner/3.png";
import logo4 from "assets/images/partner/4.png";
import logo5 from "assets/images/partner/5.png";
import logo6 from "assets/images/partner/6.png";
import logo7 from "assets/images/partner/7.png";
import logo8 from "assets/images/partner/8.png";

const data = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8];

export default data;
