import styled from "styled-components";

const ProjectListStyleWrapper = styled.div`
  
  .projects_row {
    row-gap: 30px;
  }
`;

export default ProjectListStyleWrapper;
